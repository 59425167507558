import React, {Fragment} from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton
} from '@elastic/eui'

function TOCList() {

  return (
    <Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <StaticImage
                    src='../../../../images/Software/Android/InstarVision/Qrcode_Android-AppStore.webp'
                    alt="Google Play"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
              }
              title="Google Play Link"
              description="You can use a QR Reader to install our App. Or just click the image to be directed to the Google Play Store."
              footer= {
                <Fragment>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem>
                            <EuiButton href="https://play.google.com/store/apps/details?id=de.instar.vision" target="_blank" fill size="s">
                                Google Play
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton href="https://play.google.com/store/search?q=QR%20Code%20Reader&c=apps&hl=de" target="_blank" fill size="s">
                                QR Reader
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <StaticImage
                    src='../../../../images/Software/Android/InstarVision/Amazon_app_store.webp'
                    alt="Amazon Link"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
              }
              title="Amazon Link"
              description="Download Link for our App on Amazon - for our Android / Blackberry App."
              footer= {
                <Fragment>
                    <EuiButton href="https://wiki.instar.com/Software/Other_Platforms/InstarVision_Blackberry/" target="_blank" fill size="s">
                        App Store
                    </EuiButton>
                </Fragment>
              }
            />
          </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
    </Fragment>
  );
}

export default TOCList


// export default () => {

//   return (
//     <Grid container spacing={10}>
//       <Grid item xs={12} sm={6}>
//         <Card style={{width: 345}}>
//             <CardMedia
//               style={{height: 335}}
//               image={QR}
//               title="Google Play Link"
//             />
//             <CardContent>
//               <Typography gutterBottom variant="h5" component="h2">
//                 Google Play Link
//               </Typography>
//               <Typography component="p">
//                 You can use a QR Reader to install our App. Or just click the image to be directed to the Google Play Store.
//               </Typography>
//             </CardContent>
//             <CardActions>
//               <Button href="https://play.google.com/store/apps/details?id=de.instar.vision" target="_blank" variant="contained" size="small" style={{color: '#fff', backgroundColor: '#2f78f9'}}>
//                 Google Play
//               </Button>
//               <Button href="https://play.google.com/store/search?q=QR%20Code%20Reader&c=apps&hl=de" target="_blank" variant="contained" size="small" style={{color: '#fff', backgroundColor: '#2f78f9'}}>
//                 QR Reader
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//         <Card style={{width: 345}}>
//             <CardMedia
//               style={{height: 335}}
//               image={Amazon}
//               title="Amazon Link"
//             />
//             <CardContent>
//               <Typography gutterBottom variant="h5" component="h2">
//                 Amazon Link
//               </Typography>
//               <Typography component="p">
//                 Download Link for our App on Amazon - for our Android / Blackberry App.<br/><br/>
//               </Typography>
//             </CardContent>
//             <CardActions>
//               <Button href="https://wiki.instar.com/Software/Other_Platforms/InstarVision_Blackberry/" target="_blank" variant="contained" size="small" style={{color: '#fff', backgroundColor: '#2f78f9'}}>
//                 App Store
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>
//       </Grid>
//   )
// }