import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="L5Yps1rbIFw"
                    title="SCHNELLERER App Zugriff über InstarVision auf Ihre LAN / WLAN Überwachungskamera"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>Verbessern Sie den Zugriff auf Ihre IP Überwachungskameras von INSTAR indem Sie die beste Verbindungsart für Ihren Internetanschluss wählen.</p>
                  <p>Es gibt zwei Möglichkeiten über das Internet mit der App zuzugreifen, einmal über DDNS und einmal über P2P. Wir zeigen Ihnen die Unterschiede und geben Tipps wie man den Zugriff noch schneller machen kann.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
