import React from 'react'
import {Link} from 'gatsby'

import {
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui'

import TocList from 'components/Software/Android/InstarVision/TocList-Software-InstarVision-Android'
import QRCode from 'components/Software/Android/InstarVision/TocList-Software-InstarVision-Android-QR'
import YoutubeCardP2P from 'components/Software/Android/InstarVision/instarvision_p2p_vs_ddns_video'

import NavButtons from 'components/Software/Android/NavButtons'

import SEOHelmet from 'components/Layout/SEOHelmet'
import BreadCrumbs from 'components/Layout/BreadCrumbs'


const seodata = {
  title: 'Android App InstarVision',
  description: 'The Android app InstarVision for your Android tablet or smart phone. Control your camera when you don´t have access to your computer.',
  image: '/images/Search/P_SearchThumb_InstarVision_Android.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_Android.webp',
  locationEN: '/en/Software/Android/InstarVision/',
  locationDE:'/de/Software/Android/InstarVision/',
  locationFR:'/fr/Software/Android/InstarVision/'
}

function IVAndroid(props) {
    return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="InstarVision Android App" />
          
      <NavButtons />
  
      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Android Software</h2>
      </EuiTitle>
      <EuiText>
        <p>To access your IP camera with our Android App InstarVision we recommend that you first download the newest version from the <a href="https://play.google.com/store/apps/details?id=de.instar.vision&hl=en_US" target="_blank" rel="noopener noreferrer">Play Store</a>. Use the search word Instar to find our app inside the store.</p>
      </EuiText>
      <EuiSpacer size="l" />
      <EuiTitle size="m">
        <h3>Overview over App Menus</h3>
      </EuiTitle>
      <TocList />
      <EuiSpacer size="xl" />
      <YoutubeCardP2P />
      <EuiSpacer size="xl" />
      <EuiTitle size="m">
        <h3>Download</h3>
      </EuiTitle>
      <EuiSpacer size="l" />
      <QRCode />
      <EuiSpacer size="Xl" />
      <p><em>List of <Link to="/Software/Android/InstarVision/Thirdparty_Cameras/">compatible thirdparty cameras</Link></em></p>
    </React.Fragment>
  )
}

export default IVAndroid